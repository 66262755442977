/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Image from '@common_image';
import RatingStar from '@common_ratingstar';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import { modules } from '@config';
import { useProduct } from '@core_modules/cms/components/cms-renderer/magezon/MagezonProduct/helpers/useProduct';
import { getPriceFromList } from '@core_modules/product/helpers/getPrice';
import { getLoginInfo } from '@helper_auth';
import { formatPrice } from '@helper_currency';
import { useTranslation } from '@i18n';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';

const PriceFormat = dynamic(() => import('@common_priceformat'), { ssr: false });
const Typography = dynamic(() => import('@core_modules/commons/Typography'));
const Icon = dynamic(() => import('@theme/icons/icon'));
const WeltpixelLabel = dynamic(() => import('@plugin_productitem/components/WeltpixelLabel'));

const SingleProduct = (props) => {
    // prettier-ignore
    const {
        type,
        product, product_display,
        product_compare, product_shortdescription,
        product_image, product_review,
        product_wishlist, product_name,
        item_xl, item_lg, item_md, item_sm,
        item_xs, storeConfig, dataPrice = [],
        loadingPrice, errorPrice,
    } = props;

    const isGrid = product_display && product_display === 'grid';
    const isProductGrid = type === 'product_grid';
    const isSlider = type === 'product_slider';
    const userAgent = navigator && navigator.appVersion;
    const regex = /iPhone|iPad|iPod/i;
    const isIOS = regex.test(userAgent);
    const isLogin = getLoginInfo();
    const { t } = useTranslation('product');
    // prettier-ignore
    const {
        name,
        url_key,
        id,
        review_count,
        short_description,
        small_image,
        seller,
        mp_daily_deal,
        promo_free_item,
        deal_id: mpId,
        product_name: mpName,
        url_small_image: mpImageUrl,
        original_price: mpOriginalPrice,
        deal_price: mpDealPrice,
        status: mpStatus,
        has_promo_free_item: mpPromo,
    } = product;

    // prettier-ignore

    const enableMultiSeller = storeConfig.enable_oms_multiseller === '1';
    // eslint-disable-next-line no-unused-vars
    const {
        styles,
        // wishlist,
        price,
        handleAddtowishlist,
        handleClick,
        handleSetCompareList,
    } = useProduct({ product, t });

    // const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <FavoriteBorderOutlined className={styles.iconShare} />;

    const getCol5Classes = () => {
        let classes = '';
        if (item_xl === 5) {
            classes += 'col-xl-5 ';
        }
        if (item_lg === 5) {
            classes += 'col-lg-5 ';
        }
        if (item_md === 5) {
            classes += 'col-md-5 ';
        }
        if (item_sm === 5) {
            classes += 'col-sm-5 ';
        }
        if (item_xs === 5) {
            classes += 'col-xs-5 ';
        }

        return classes;
    };

    const responsiveProps = isProductGrid
        ? {
            xl: isProductGrid && item_xl !== 5 && 12 / item_xl,
            lg: isProductGrid && item_lg !== 5 && 12 / item_lg,
            md: isProductGrid && item_md !== 5 && 12 / item_md,
            sm: isProductGrid && item_sm !== 5 && 12 / item_sm,
            xs: isProductGrid && item_xs !== 5 && 12 / item_xs,
        }
        : {};

    const priceData = getPriceFromList(dataPrice, product.id);

    let defaultWidth = storeConfig?.pwa?.image_product_width;
    let defaultHeight = storeConfig?.pwa?.image_product_height;

    if (typeof defaultWidth === 'string') defaultWidth = parseInt(defaultWidth, 0);
    if (typeof defaultHeight === 'string') defaultHeight = parseInt(defaultHeight, 0);

    const generatePrice = (priceDataItem, priceItem, mpDailyDeal) => {
        // handle if loading price
        if (loadingPrice) {
            return (
                <div className="mgz-single-product-price">
                    <Skeleton variant="text" width={100} />{' '}
                </div>
            );
        }

        let priceProduct = priceItem;
        if (priceDataItem.length > 0 && !loadingPrice && !errorPrice) {
            priceProduct = {
                priceRange: priceDataItem[0].price_range,
                priceTiers: priceDataItem[0].price_tiers,
                // eslint-disable-next-line no-underscore-dangle
                productType: priceDataItem[0].__typename,
                specialFromDate: priceDataItem[0].special_from_date,
                specialToDate: priceDataItem[0].special_to_date,
            };
        }
        return (
            <div className={
                classNames(mpDailyDeal?.status === 1 ? styles.haveDeal : '',
                    // eslint-disable-next-line no-underscore-dangle
                    product.__typename === 'BundleProduct' ? styles.bundle : '',
                    'mgz-single-product-price')
            }
            >
                {priceProduct && <PriceFormat {...priceProduct} />}
            </div>
        );
    };
    const generateDealPrice = (mpDailyDeal) => {
        let dealPrice = '';

        if (mpDailyDeal && mpDailyDeal.deal_price && mpDailyDeal.status) {
            dealPrice = {
                deal_price: formatPrice(mpDailyDeal.deal_price, 'IDR').replace(',00', ''),
            };
        }

        return (
            <>
                {dealPrice && mpDailyDeal.status ? (
                    <div className={styles.dealPrice}>
                        <Typography variant="p" type="bold">
                            {dealPrice.deal_price}
                        </Typography>
                    </div>
                ) : (
                    <div className={styles.spacingPrice} />
                )}
            </>
        );
    };

    const generateMpOriginalPrice = (MpOriginalPrice) => {
        let regularPrice = '';
        if (MpOriginalPrice) {
            regularPrice = {
                original_price: formatPrice(Math.floor(MpOriginalPrice), 'IDR').replace(',00', ''),
            };
        }

        return (
            <>
                {regularPrice && (
                    <div className={styles.originalPrice}>
                        <Typography variant="p" type="bold">
                            {regularPrice.original_price}
                        </Typography>
                    </div>
                )}
            </>
        );
    };

    const generateMpDealPrice = (MpDealPrice, MpStatus) => {
        let dealPrice = '';
        if (MpDealPrice && MpStatus) {
            dealPrice = {
                price: formatPrice(MpDealPrice).replace(',00', ''),
            };
        }

        return (
            <>
                {dealPrice && mpStatus && (
                    <div className={styles.dealPrice}>
                        <Typography variant="p" type="bold">
                            {dealPrice.price}
                        </Typography>
                    </div>
                )}
            </>
        );
    };

    const percentOff = price
        && price.priceRange
        && price.priceRange.minimum_price
        && price.priceRange.minimum_price.discount
        && price.priceRange.minimum_price.discount.percent_off;

    return (
        <>
            <Grid
                item={isProductGrid}
                container
                direction={isGrid || isProductGrid || isSlider ? 'column' : 'row'}
                alignItems={isGrid || isSlider ? 'center' : 'stretch'}
                className={`mgz-single-product-card ${getCol5Classes()}`}
                {...responsiveProps}
            >
                {storeConfig?.pwa?.label_enable && storeConfig?.pwa?.label_weltpixel_enable && (
                    <WeltpixelLabel t={t} weltpixel_labels={product.weltpixel_labels} categoryLabel />
                )}
                {product_image && (
                    <Grid
                        item
                        xs={isGrid ? 6 : isProductGrid || isSlider ? false : 3}
                        container
                        justify="center"
                        alignItems={isProductGrid ? 'center' : 'stretch'}
                    >
                        <Link href={handleClick} prefetch={false}>
                            <a style={{ width: defaultWidth }}>
                                <Image
                                    src={small_image?.url ? small_image.url : mpImageUrl}
                                    width={defaultWidth}
                                    height={defaultHeight}
                                    alt={name}
                                    storeConfig={storeConfig}
                                />
                            </a>
                        </Link>
                        <div className={styles.labelWrapper}>
                            {mp_daily_deal?.status || mpStatus ? <span className={styles.flashSaleLabel}>{t('product:flashsale')}</span> : ''}
                            {promo_free_item?.promo_item || mpPromo ? <span className={styles.freeItemLabel}>{t('product:freeitem')}</span> : ''}
                        </div>
                        {
                            percentOff !== 0 && (
                                <div className={styles.percentOffProduct}>
                                    <p>{`Save ${Math.trunc(percentOff)}%`}</p>
                                </div>
                            )
                        }
                    </Grid>
                )}
                <Grid
                    item
                    xs={!isSlider}
                    container={!isIOS}
                    direction="column"
                    justify="center"
                    alignItems={isGrid || isProductGrid || isSlider ? 'center' : 'stretch'}
                    className={mp_daily_deal ? styles.flashSale : ''}
                >
                    {product_name && (
                        <Grid item>
                            <Link href={handleClick} prefetch={false}>
                                <a title={name}>
                                    <Typography variant="h4" className={styles.productTitle}>
                                        {name?.length ? name : mpName}
                                    </Typography>
                                </a>
                            </Link>
                        </Grid>
                    )}
                    {enableMultiSeller && seller.seller_name && (
                        <div className={styles.infoSeller}>
                            <StorefrontIcon className={styles.iconSeller} />
                            <Typography variant="h4">{seller.seller_name}</Typography>
                        </div>
                    )}
                    <Grid item container justify={isGrid || isProductGrid || isSlider ? 'center' : 'flex-start'} className={styles.priceContainer}>
                        <Grid item>
                            {mpId ? generateMpOriginalPrice(mpOriginalPrice) : generatePrice(priceData, price, mp_daily_deal)}
                            {mpId ? generateMpDealPrice(mpDealPrice, mpStatus) : generateDealPrice(mp_daily_deal)}

                            {product_shortdescription && (
                                <Link href={url_key} prefetch={false}>
                                    <CmsRenderer content={short_description.html} />
                                </Link>
                            )}
                        </Grid>
                        {!isGrid && !isSlider && (
                            <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                {modules.wishlist.enabled && product_wishlist && (
                                    <IconButton className={styles.btnShare} onClick={handleAddtowishlist}>
                                        <Icon className={styles.iconShare} icon="heart" size={20} />
                                    </IconButton>
                                )}
                                {modules.productcompare.enabled && product_compare && (
                                    <IconButton className={styles.btnShare} onClick={() => handleSetCompareList(id)}>
                                        <Icon className={styles.iconShare} icon="compare" size={20} />
                                    </IconButton>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {(isGrid || isSlider) && (
                        <Grid item className={styles.bottomElement}>
                            <Grid container spacing={0} className={styles.textRight}>
                                <Grid item>
                                    {product_review && (
                                        <Grid
                                            item
                                            container
                                            justify={isGrid || isProductGrid || isSlider ? 'center' : 'flex-start'}
                                            style={{ padding: '12px' }}
                                        >
                                            <RatingStar value={review_count} sizeIcon={24} />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item>
                                    {modules.wishlist.enabled && product_wishlist && (
                                        <IconButton className={styles.btnShare} aria-label="wishlist" onClick={handleAddtowishlist}>
                                            <Icon className={styles.iconShare} icon="heart" size={20} />
                                        </IconButton>
                                    )}
                                    {modules.productcompare.enabled && product_compare && (
                                        <IconButton
                                            aria-label="compare"
                                            className={styles.btnShare}
                                            onClick={() => {
                                                if (isLogin) {
                                                    handleSetCompareList(id);
                                                } else {
                                                    window.toastMessage({
                                                        open: true,
                                                        text: t('common:productCompare:addWithoutLogin'),
                                                        variant: 'warning',
                                                    });
                                                }
                                            }}
                                        >
                                            <Icon className={styles.iconShare} icon="compare" size={20} />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <style jsx>
                {`
                    .mgz-single-product-option-item {
                        margin-top: 10px;
                    }
                    .mgz-single-product-option-item :global(> div:not(div[class*='btnAdd'])) {
                    }
                    .mgz-single-product-option-item :global(div[role='radiogroup']) {
                        ${isGrid ? 'justify-content: center;' : ''}
                    }
                    .mgz-single-product-price {
                        ${isGrid || isProductGrid ? 'text-align: center;' : ''}
                    }
                    @media (max-width: 600px) {
                        .mgz-single-product-option-item :global(div[role='radiogroup'] > div) {
                            margin: 2px 5px;
                        }
                        .mgz-single-product-option-item :global(div[role='radiogroup'] > div > span) {
                            font-size: 11px;
                        }
                        .mgz-single-product-option-item :global(div[class*='btnAdd'] button) {
                            font-size: 11px;
                            height: 31px;
                        }
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .mgz-single-product-card:hover {
                        ${isProductGrid
                        && `
                            border: 1px solid #bbbbbb;
                            position: relative;
                            z-index: 2;
                        `}
                    }
                    .mgz-single-product-price {
                        display: flex;
                        flex-direction: column;
                    }
                    .mgz-single-product-price span {
                        color: #ff0000;
                        font-size: 15px;
                        font-family: Plus Jakarta Sans Bold, sans-serif;
                        margin-top: 5px;
                    }
                    .mgz-single-product-price span.old_price {
                        font-size: 13px;
                        line-height: 16px;
                    }
                    .mgz-single-product-price span.special_price {
                        line-height: 19px;
                    }
                    .mgz-single-product-price span strike {
                        color: #afafaf;
                        font-size: 13px;
                        font-weight: bold;
                        margin: 0;
                    }
                    @media (max-width: 767px) {
                        .mgz-single-product-price span.old_price {
                            font-size: 12px;
                            margin-top: 10px;
                            display: inline;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .mgz-single-product-price span.price_text,
                        .mgz-single-product-price span.special_price,
                        .mgz-single-product-price span.normal_price {
                            font-size: 14px;
                            display: inline;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default SingleProduct;
