import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    BLACK, GRAY_PRICE, GRAY_PRIMARY, RED, WHITE,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    textRight: {
        textAlign: 'right !important',
    },
    bottomElement: {
        marginTop: 'auto !important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
        },
    },
    btnShare: {
        margin: '0px !important',
        '&:hover': {
            background: 'unset',
        },
    },
    iconShare: {
        color: '#b9b9b9',
        marginLeft: 15,
    },
    btnCompare: {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        width: '120px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            background: WHITE,
        },
    },
    btnAddToCart: {
        width: '100%',
        height: 41,
        color: WHITE,
        borderRadius: 100,
        [theme.breakpoints.down('576')]: {
            fontSize: 9,
            height: 31,
        },
    },
    contentContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            width: 750,
        },
        [theme.breakpoints.up('md')]: {
            width: 970,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
    },
    contentFeatured: {
        transition: '0.3s',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        [theme.breakpoints.up('sm')]: {
            padding: 8,
        },
    },
    contentMobileFeatured: {
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    infoSeller: {
        display: 'flex',
    },
    iconSeller: {
        fontSize: 18,
        color: GRAY_PRIMARY,
        fontWeight: '200',
        marginRight: 5,
    },
    productTitle: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    haveDeal: {
        '& .price_text, .old_price, .normal_price': {
            textDecoration: 'line-through',
            fontSize: 13,
            color: GRAY_PRICE,
            marginTop: 10,
            lineHeight: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px !important',
            },
        },
    },
    priceContainer: {
        overflow: 'hidden',
    },
    dealPrice: {
        '& p': {
            fontSize: 15,
            lineHeight: '19px',
            fontFamily: 'Plus Jakarta Sans Bold, sans-serif !important',
            color: RED,
            margin: '5px 0 10px',

            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
    },
    originalPrice: {
        '& p': {
            fontSize: 13,
            lineHeight: '16px',
            textDecoration: 'line-through',
            color: GRAY_PRICE,
            margin: '10px 0 0',
        },
    },
    flashSale: {
        '& [class*="normal_price"]': {
            fontSize: 13,
        },
        '& [class*="special_price"]': {
            display: 'none',
        },
    },
    labelWrapper: {
        position: 'absolute',
        top: 10,
        right: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& span': {
            marginBottom: 2,
            fontSize: 12,
            fontWeight: 'bold',
            padding: '2px 5px',
            borderRadius: 5,
            width: '68px',
            textAlign: 'center',
        },
    },
    bundle: {
        flexDirection: 'initial !important',
        '& .max': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    flashSaleLabel: {
        backgroundColor: '#FFE604',
        color: '#ff0000',
    },
    freeItemLabel: {
        backgroundColor: '#FFE604',
        color: BLACK,
    },
    spacingPrice: {
        [theme.breakpoints.up('sm')]: {
            height: '1.5rem',
        },
    },
    percentOffProduct: {
        position: 'absolute',
        right: '14px',
        bottom: '46%',
        backgroundColor: '#009547',
        padding: '4px 8px',
        borderTopLeftRadius: '40px',
        zIndex: '99',
        '& p': {
            margin: '0',
            color: WHITE,
            fontSize: '12px',
            fontWeight: '600',
        },
        [theme.breakpoints.down('1100')]: {
            bottom: '47%',
        },
        [theme.breakpoints.down('1025')]: {
            bottom: '48%',
        },
        [theme.breakpoints.down('768')]: {
            bottom: '46%',
        },
    },
}));

export default useStyles;
