import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import { FlexRow } from '@theme_mixins';

export default makeStyles(() => ({
    container: {
        ...FlexRow,
    },
    iconBtn: {
        marginRight: 0,
        padding: 0,
        color: '#f7c318',
    },
    icon: {
        color: '#f7c318',
        '&:hover': {
            color: '#f7c318 !important',
        },
    },
    iconActive: {
        color: '#f7c318',
    },
}));
